<div class="bg-blue p-3 py-md-3" id="form" style="z-index: 9999999">
    <div class="text-right d-md-none">
        <fa-icon [icon]="closeIcon" class="text-white" size="2x" (click)="closeModal()"></fa-icon>
    </div>
    <h5 class="text-white font-weight-bold text-center">Find out more!</h5>
    <p class="text-white font-weight-lighter mb-4">Please, fill in the form and we will contact you with further information on
        the Program of your choice
    </p>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group styled-select">
            <div class="input-group">
                <select #select1 class="form-control updateable" formControlName="category">
                    <option value="" selected disabled>Select program *</option>
                    <option *ngFor="let c of courseCategoryService.categories" [value]="c.value" [hidden]="!(form.controls['campus'].value !== 'madrid') && c.value === 'dba'">{{c.name | titlecase}}</option>
                </select>
                <span class="input-group-text">
                    <fa-icon [icon]="selectIcon"></fa-icon>
                </span>
            </div>
        </div>

        <div class="row no-gutters">
            <div class="col-6 pr-1">
                <div class="form-group styled-select">
                    <div class="input-group">
                        <select class="form-control updateable" formControlName="formula">
                            <option value="" selected disabled>Select formula *</option>
                            <option value="full-time">Full time</option>
                            <option value="part-time" [disabled]="form.getRawValue().category === 'bba'">Part time</option>
                        </select>
                        <span class="input-group-text">
                            <fa-icon [icon]="selectIcon"></fa-icon>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-6 pl-1">
                <div class="form-group styled-select">
                    <div class="input-group">
                        <select class="form-control updateable" formControlName="location">
                            <option value="" selected disabled>Select mode *</option>
                            <option *ngFor="let mode of modeService.modes" [value]="mode">{{mode | titlecase}}</option>
<!--                            <option value="madrid" *ngIf="form.controls['category'].value !== 'dba'">Madrid campus</option>-->
                        </select>
                        <span class="input-group-text">
                            <fa-icon [icon]="selectIcon"></fa-icon>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-6 pr-1">
                <div class="form-group styled-select">
                    <div class="input-group">
                        <select class="form-control updateable" formControlName="campus">
                            <option value="" selected disabled>Select campus *</option>
                            <option *ngFor="let c of campusList" [hidden]="!(form.controls['category'].value !== 'dba') && c === 'madrid'" [value]="c">{{c | titlecase}}</option>
                        </select>
                        <span class="input-group-text">
                            <fa-icon [icon]="selectIcon"></fa-icon>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row no-gutters">
            <div class="col-6 pr-1">
                <div class="form-group">
                    <input class="form-control" name="firstName" type="text" placeholder="Name *" formControlName="name">
                </div>
            </div>
            <div class="col-6 pl-1">
                <div class="form-group">
                    <input class="form-control" name="lastName" type="text" placeholder="Surname *" formControlName="surname">
                </div>
            </div>
        </div>

        <div class="row no-gutters">
            <div class="col-6 pr-1">
                <div class="form-group">
                    <select class="form-control" formControlName="age" name="birthYear">
                        <option value="" selected disabled>Select birth year *</option>
                        <option *ngFor="let y of years" value="{{y}}">{{y}}</option>
                    </select>
                </div>
            </div>
            <div class="col-6 pl-1 text-center text-sm-left" style="height:44px; display: flex; justify-content: center; align-items: center">
                <div class="px-2">
                    <div class="form-check form-check-inline" style="border: none; outline: none">
                        <input class="form-check-input" type="radio" id="gender-male" value="male" name="gender" formControlName="gender">
                        <label class="form-check-label text-white" for="gender-male">M</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" id="inlineCheckbox2" value="female" name="gender" formControlName="gender">
                        <label class="form-check-label text-white" for="inlineCheckbox2">F</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">
                    <fa-icon [icon]="mailIcon"></fa-icon>
                </div>
            </div>
            <input class="form-control" type="text" placeholder="Email *" formControlName="email">
        </div>
        <div class="alert alert-danger mt-2" *ngIf="form.controls.email.touched && form.controls.email?.errors?.pattern">
            Please enter a valid email address
        </div>

        <!--<div class="input-group mb-3">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <fa-icon [icon]="phoneIcon"></fa-icon>
            </div>
          </div>
          <input type="text" class="form-control" placeholder="Phone number *" formControlName="phone">
        </div>-->

        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">
                    <fa-icon [icon]="phoneIcon"></fa-icon>
                </div>
            </div>
            <select class="form-control" formControlName="countryCode" style="height: 44px">
                <option value="" selected disabled>Area code</option>
                <option *ngFor="let c of countries" [value]="c.dial_code">{{flags.get(c.code).emoji}} {{c.name}} {{c.dial_code}}</option>
            </select>
            <input type="text" class="form-control w-50" formControlName="phone" placeholder="Phone number *">
        </div>
        <div class="alert alert-danger mt-2" *ngIf="form.controls.phone.touched && (form.controls.phone?.errors?.maxlength || form.controls.phone?.errors?.minlength)">
            Please enter a valid phone number
        </div>

        <p class="small text-white mt-3" style="font-size: 0.6rem!important;">
            Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC.
        </p>

        <div class="form-check text-center">
            <input class="form-check-input" type="checkbox" formControlName="privacy" id="privacy">
            <label class="form-check-label checkboxtext text-white ml-2" for="privacy" style="font-size: 0.8rem!important;">
                I have read and accept the <a class="text-white" href="https://www.iubenda.com/privacy-policy/13761172" target="_blank">Privacy Policy</a>.
            </label>
        </div>

        <div class="form-group text-center mt-3 mx-auto">
            <button class="btn text-white font-weight-bold px-4" style="width: 199px; background: #2663F8 0% 0% no-repeat padding-box;border-radius: 30px;" [disabled]="form.invalid">SUBMIT</button>
        </div>

    </form>
</div>
